














































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  IMerchant,
  IMerchantCreate,
} from '@/interfaces';
import {
    dispatchCreateMerchant,
    dispatchGetTOS,
} from '@/store/admin/actions';

import { dispatchAskPaymentEmail } from '@/store/affi/actions';
import { readTOS } from '@/store/admin/getters';

import { ttt } from '@/nutils';


import { Validator } from 'vee-validate';

Validator.extend('isEqual', {
    getMessage: field => field + ' is invalid.',
    validate: value => value
});


@Component
export default class CreateMerchantOpen extends Vue {
  public ttt = ttt;
  public valid = false;
  public firstName: string = '';
  public lastName: string = '';
  public shopName: string = '';
  public shopURL: string = '';
  public email: string = '';
  public tos: boolean = false;
  public privacyPolicy: boolean = false;
  public dataProcAgree: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public sig: string = '';

  public dialog: boolean = false;

  public async mounted() {
    await dispatchGetTOS(this.$store);
    this.shopURL = this.$route.query.shop_url.toString();
    this.sig = this.$route.query.sig.toString();
    this.reset();
  }

  public reset() {
    this.valid = false;
    this.password1 = '';
    this.password2 = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.shopName = '';
    this.tos = false;
    this.privacyPolicy = false;
    this.dataProcAgree = false;
    this.$validator.reset();
  }

  get tosText() {
    return readTOS(this.$store);
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const newMerchant: IMerchantCreate = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        shop_name: this.shopName,
        shop_url: this.shopURL,
        tos: this.tos,
        privacy_policy: this.privacyPolicy,
        data_proc_agree: this.dataProcAgree,
        sig: this.sig,
      }
      newMerchant.password = this.password1;
      const ret = await dispatchCreateMerchant(this.$store, newMerchant);

      if (ret) {
        const data = {
            email: this.email,
            callback: ( resp: any ) => {
                // tslint:disable-next-line:no-console
                console.log(`going to ${resp}`);
                window.location.href = resp.msg;
            }
        };
        await dispatchAskPaymentEmail(this.$store, data);
      }
    }
  }
}
